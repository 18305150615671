import { faArrowCircleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useState } from "react";
import EventiGrid from "../../components/EventiGrid/EventiGrid";
import Layout from "../../components/Layout";

export default function GratuitiFADPage({ data }) {
  const {
    totaleEventi: { totalCount },
    allEventiFad: { nodes: eventiFad },
  } = data;

  const [pagina, setPagina] = useState(1);
  const eventiFiltered = eventiFad.slice(0, 20 * pagina);

  return (
    <Layout
      pageTitle="Corsi ECM FAD Gratuiti"
      description={`Scegli tra i ${totalCount} corsi ECM FAD gratuiti accreditati da Agenas per tutte le professioni sanitarie`}
    >
      <section className="position-relative">
        <StaticImage
          src="../../images/eventi-fad.jpg"
          className="img-header-home"
          alt="Corsi ECM FAD"
          title="Corsi ECM FAD"
        />
        <div className="container-fluid px-0 py-5 container-absolute-450 d-flex flex-column justify-content-center">
          <div className="container py-5">
            <h1 className="text-center text-white display-3">
              Corsi ECM FAD Gratuiti
            </h1>
            <p
              className="text-center text-white"
              style={{ fontSize: 22, fontWeight: 400 }}
            >
              Scegli tra i {totalCount} corsi ECM FAD gratuiti accreditati da
              Agenas per tutte le professioni sanitarie
            </p>
            <a href="#sezione-corsi" className="btn btn-outline-warning">
              <FontAwesomeIcon icon={faArrowCircleDown} className="mr-2" />{" "}
              Scopri i corsi
            </a>
          </div>
        </div>
      </section>
      <div className="container-fluid" id="sezione-corsi">
        <div className="row px-5 py-3">
          <div className="col-12">
            <h2 className="my-4 text-center titolo-sezione">
              Corsi ECM FAD Gratuiti in evidenza
            </h2>
          </div>
        </div>
      </div>
      <EventiGrid eventi={eventiFiltered} tipologia="FAD" />
      {eventiFiltered.length < eventiFad.length && (
        <div className="container-fluid">
          <div className="row px-5 pt-3 pb-5">
            <div className="col-12 text-center">
              <button
                className="btn btn-outline-primary"
                onClick={() => setPagina((prev) => prev + 1)}
              >
                Carica altri corsi
              </button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    totaleEventi: allEventiJson(
      filter: {
        tipo_evento: { eq: "FAD" }
        quota_partecipazione: { eq: "0,00 €" }
      }
    ) {
      totalCount
    }
    allEventiFad: allEventiJson(
      filter: {
        tipo_evento: { eq: "FAD" }
        quota_partecipazione: { eq: "0,00 €" }
      }
      sort: { fields: data_fine_timestamp, order: DESC }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        professioni {
          disciplina
          professione
        }
      }
    }
  }
`;
